module.exports = [{
      plugin: require('/app/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1600,"quality":90,"linkImagesToOriginal":false},
    },{
      plugin: require('/app/node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/app/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://corezoid.com"},
    },{
      plugin: require('/app/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
