// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-blog-js": () => import("/app/src/templates/Blog/Blog.js" /* webpackChunkName: "component---src-templates-blog-blog-js" */),
  "component---src-templates-post-post-js": () => import("/app/src/templates/Post/Post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-pages-404-js": () => import("/app/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/app/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aws-js": () => import("/app/src/pages/aws.js" /* webpackChunkName: "component---src-pages-aws-js" */),
  "component---src-pages-bot-platform-apple-messages-for-business-js": () => import("/app/src/pages/bot-platform/apple-messages-for-business.js" /* webpackChunkName: "component---src-pages-bot-platform-apple-messages-for-business-js" */),
  "component---src-pages-bot-platform-facebook-js": () => import("/app/src/pages/bot-platform/facebook.js" /* webpackChunkName: "component---src-pages-bot-platform-facebook-js" */),
  "component---src-pages-bot-platform-google-business-messages-js": () => import("/app/src/pages/bot-platform/google-business-messages.js" /* webpackChunkName: "component---src-pages-bot-platform-google-business-messages-js" */),
  "component---src-pages-bot-platform-index-js": () => import("/app/src/pages/bot-platform/index.js" /* webpackChunkName: "component---src-pages-bot-platform-index-js" */),
  "component---src-pages-bot-platform-telegram-js": () => import("/app/src/pages/bot-platform/telegram.js" /* webpackChunkName: "component---src-pages-bot-platform-telegram-js" */),
  "component---src-pages-bot-platform-viber-js": () => import("/app/src/pages/bot-platform/viber.js" /* webpackChunkName: "component---src-pages-bot-platform-viber-js" */),
  "component---src-pages-caab-js": () => import("/app/src/pages/caab.js" /* webpackChunkName: "component---src-pages-caab-js" */),
  "component---src-pages-case-studies-js": () => import("/app/src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-certification-confirm-js": () => import("/app/src/pages/certification/confirm.js" /* webpackChunkName: "component---src-pages-certification-confirm-js" */),
  "component---src-pages-certification-index-js": () => import("/app/src/pages/certification/index.js" /* webpackChunkName: "component---src-pages-certification-index-js" */),
  "component---src-pages-certification-junior-js": () => import("/app/src/pages/certification/junior.js" /* webpackChunkName: "component---src-pages-certification-junior-js" */),
  "component---src-pages-cloud-js": () => import("/app/src/pages/cloud.js" /* webpackChunkName: "component---src-pages-cloud-js" */),
  "component---src-pages-code-js": () => import("/app/src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-communication-js": () => import("/app/src/pages/communication.js" /* webpackChunkName: "component---src-pages-communication-js" */),
  "component---src-pages-download-js": () => import("/app/src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-events-js": () => import("/app/src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("/app/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-form-caab-js": () => import("/app/src/pages/form/caab.js" /* webpackChunkName: "component---src-pages-form-caab-js" */),
  "component---src-pages-form-certification-corp-js": () => import("/app/src/pages/form/certification-corp.js" /* webpackChunkName: "component---src-pages-form-certification-corp-js" */),
  "component---src-pages-form-certification-employees-js": () => import("/app/src/pages/form/certification-employees.js" /* webpackChunkName: "component---src-pages-form-certification-employees-js" */),
  "component---src-pages-form-certification-partner-js": () => import("/app/src/pages/form/certification-partner.js" /* webpackChunkName: "component---src-pages-form-certification-partner-js" */),
  "component---src-pages-form-extended-trial-js": () => import("/app/src/pages/form/extended-trial.js" /* webpackChunkName: "component---src-pages-form-extended-trial-js" */),
  "component---src-pages-form-index-js": () => import("/app/src/pages/form/index.js" /* webpackChunkName: "component---src-pages-form-index-js" */),
  "component---src-pages-form-live-demo-js": () => import("/app/src/pages/form/live-demo.js" /* webpackChunkName: "component---src-pages-form-live-demo-js" */),
  "component---src-pages-form-sales-js": () => import("/app/src/pages/form/sales.js" /* webpackChunkName: "component---src-pages-form-sales-js" */),
  "component---src-pages-form-submit-vacancy-js": () => import("/app/src/pages/form/submit-vacancy.js" /* webpackChunkName: "component---src-pages-form-submit-vacancy-js" */),
  "component---src-pages-form-support-js": () => import("/app/src/pages/form/support.js" /* webpackChunkName: "component---src-pages-form-support-js" */),
  "component---src-pages-form-unsubscribe-js": () => import("/app/src/pages/form/unsubscribe.js" /* webpackChunkName: "component---src-pages-form-unsubscribe-js" */),
  "component---src-pages-form-webinar-js": () => import("/app/src/pages/form/webinar.js" /* webpackChunkName: "component---src-pages-form-webinar-js" */),
  "component---src-pages-form-workshop-js": () => import("/app/src/pages/form/workshop.js" /* webpackChunkName: "component---src-pages-form-workshop-js" */),
  "component---src-pages-index-js": () => import("/app/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iot-js": () => import("/app/src/pages/iot.js" /* webpackChunkName: "component---src-pages-iot-js" */),
  "component---src-pages-jobs-js": () => import("/app/src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-knowledge-js": () => import("/app/src/pages/knowledge.js" /* webpackChunkName: "component---src-pages-knowledge-js" */),
  "component---src-pages-learn-js": () => import("/app/src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-manage-api-js": () => import("/app/src/pages/manage-api.js" /* webpackChunkName: "component---src-pages-manage-api-js" */),
  "component---src-pages-media-js": () => import("/app/src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-new-pricing-private-cloud-js": () => import("/app/src/pages/new-pricing/private-cloud.js" /* webpackChunkName: "component---src-pages-new-pricing-private-cloud-js" */),
  "component---src-pages-new-pricing-public-cloud-js": () => import("/app/src/pages/new-pricing/public-cloud.js" /* webpackChunkName: "component---src-pages-new-pricing-public-cloud-js" */),
  "component---src-pages-new-pricing-success-js": () => import("/app/src/pages/new-pricing/success.js" /* webpackChunkName: "component---src-pages-new-pricing-success-js" */),
  "component---src-pages-new-pricing-support-js": () => import("/app/src/pages/new-pricing/support.js" /* webpackChunkName: "component---src-pages-new-pricing-support-js" */),
  "component---src-pages-partnership-js": () => import("/app/src/pages/partnership.js" /* webpackChunkName: "component---src-pages-partnership-js" */),
  "component---src-pages-people-js": () => import("/app/src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-pricing-js": () => import("/app/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-pricing-pay-js": () => import("/app/src/pages/pricing/pay.js" /* webpackChunkName: "component---src-pages-pricing-pay-js" */),
  "component---src-pages-pricing-private-cloud-js": () => import("/app/src/pages/pricing/private-cloud.js" /* webpackChunkName: "component---src-pages-pricing-private-cloud-js" */),
  "component---src-pages-pricing-public-cloud-js": () => import("/app/src/pages/pricing/public-cloud.js" /* webpackChunkName: "component---src-pages-pricing-public-cloud-js" */),
  "component---src-pages-pricing-support-js": () => import("/app/src/pages/pricing/support.js" /* webpackChunkName: "component---src-pages-pricing-support-js" */),
  "component---src-pages-processes-js": () => import("/app/src/pages/processes.js" /* webpackChunkName: "component---src-pages-processes-js" */),
  "component---src-pages-product-js": () => import("/app/src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-software-js": () => import("/app/src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("/app/src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-tutorials-js": () => import("/app/src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */),
  "component---src-pages-workshops-js": () => import("/app/src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

